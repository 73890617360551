import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, Input, input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { ArkUserAvatarComponent } from '@ark';
import { GthFeedbackDialogComponent, GthFeedbackService } from '@gth-legacy';
import { GthNotificationModel, GthUserModel } from '@sentinels/models';
import { AvatarComponentModule } from '@shared/dialogs/avatar/avatar.component';
import { APP_ROUTES } from '@shared/helpers';
import { AppsMenuComponent } from '@shared/layouts/nav-bar-layout/components/apps-menu/apps-menu.component';
import { DISCOVER_MENU } from '@shared/layouts/nav-bar-layout/views/nav-bar.component';
import { AppSidenavService } from '@shared/services/sidenav.service';
import { of } from 'rxjs';

import { NotificationsSidenavComponent } from '../notifications-sidenav/notifications-sidenav.component';

export interface NavBavOptions {
  pageName?: string;
}

@Component({
  selector: 'app-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatToolbarModule,
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    AvatarComponentModule,
    MatDividerModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    ArkUserAvatarComponent,
    NotificationsSidenavComponent,
    AppsMenuComponent,
  ],
})
export class TopNavBarComponent implements OnInit {
  @Output() logout = new EventEmitter<void>();
  @Input() showSelf = true;
  @Input() user : GthUserModel;
  @Input() numUnreadMessages : any;// todo chage
  @Input() loadingUser : boolean;

  notifications = input<{ [key: string]: GthNotificationModel }>({});
  notificationCount = computed(() =>
    Object.values(this.notifications()).filter((n) => !n.read).length,
  );
  filteredNotifications = computed(() => {
    return Object.values(this.notifications()).reduce((acc, n) => {
      if (n.read) {
        acc.read.push(n);
      } else {
        acc.unread.push(n);
      }
      return acc;
    }, { read: [], unread: [] });
  });
  hasUnreadNotifications = computed(() => this.filteredNotifications().unread.length > 0);
  hasNotifications = computed(() => Object.values(this.notifications()).length > 0);

  protected readonly APP_ROUTES = APP_ROUTES;
  discoverMenu = DISCOVER_MENU;
  notificationTime: number;
  debugging = false;
  disableNotifications = true;

  constructor(
    private sidenav: AppSidenavService,
    private dialog: MatDialog,
    private feedback: GthFeedbackService,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.notificationTime = new Date().getTime();
  }

  onNotificationMenuClick(notifications: TemplateRef<unknown>) {
    this.sidenav.ctrl.set({
      title: 'Notifications',
      portalContent: notifications,
    });
  }

  async onAuthBtnClick(user?: GthUserModel) {
    this.logout.emit();
  }

  userAsObs$() {
    return of(this.user);
  }

  onFeedbackButtonClick() {
    const dialogRef = this.dialog.open(GthFeedbackDialogComponent, {
      backdropClass: 'gth-overlay-backdrop',
      panelClass: 'gth-dialog--custom-size',
      height: '500px',
      width: '550px',
      maxWidth: '100%',
    });

    dialogRef.afterClosed().subscribe(async (feedback) => {
      if (!feedback) return;
      await this.feedback.submitFeedback(feedback);
      this.snackbar.open('Thank you for your feedback!');
    });
  }
}
