// user.actions.ts

import { createAction, props, union } from '@ngrx/store';

export enum ActionTypes {
    NAV_LAYOUT_BOTTOM_STATE= '[NAV LAYOUT] State',
}

export const navLayoutBottomSetState= createAction(
    ActionTypes.NAV_LAYOUT_BOTTOM_STATE,
    props<{ isCollapsed: boolean }>(),
);

export const allNavLayoutActions = union({
    navLayoutBottomSetState,
  });

export type UserActionsUnion = typeof allNavLayoutActions;
