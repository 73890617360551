import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface SmallIconProperties {
  name: string,
  url: string | string[],
  image: string,
  fragment?: string,
  params?: { [key: string]: string },
}

@Component({
  selector: 'app-small-nav-icon',
  templateUrl: './small-nav-icon.component.html',
  styleUrls: ['./small-nav-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SmallNavIconComponent {
  @Input() iconProps!: SmallIconProperties;
}
