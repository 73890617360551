import { isPlatformServer } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output, PLATFORM_ID, Renderer2 } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import {
  filter,
  map,
  pairwise,
  share,
  takeUntil,
  throttleTime,
} from 'rxjs/operators';

// Inspired by: https://netbasal.com/reactive-sticky-header-in-angular-12dbffb3f1d3

/**
 * The `appHideOnScroll` directive allows you to hide an html element
 * (e.g. navbar) on scroll down and show it again on scroll up.
 */
@Directive({
  selector: '[appHideOnScroll]',
})
export class NgxHideOnScrollDirective implements AfterViewInit, OnDestroy {
  /**
   * `'Down'`: The element will be hidden on scroll down
   * and it will be shown again on scroll up.
   * `Up`: The element will be hidden on scroll up and it will be shown again on scroll down.
   */
  @Input() hideOnScroll: 'Down' | 'Up' = 'Down';

  /**
   * CSS class name added to the element to hide it.
   * When this property is set:
   * `propertyUsedToHide`, `valueWhenHidden`, and `valueWhenShown` have not effect.
   */
  @Input() classNameWhenHidden = '';


  @Input() hiddenOver = '';
  /**
   * The CSS property used to hide/show the element.
   *
   * @default
   * 'transform'
   */
  @Input() propertyUsedToHide: 'transform' | 'top' | 'bottom' | 'height' = 'transform';

  /**
   * The value of `propertyUsedToHide` when the element is hidden.
   *
   * @default
   * 'translateY(-100%)'
   */
  @Input() valueWhenHidden = 'translateY(-100%)';

  /**
   * The value of `propertyUsedToHide` when the element is shown.
   *
   * @default
   * 'translateY(0)'
   */
  @Input() valueWhenShown = 'translateY(0)';

  /**
     * show element if below y threshold.
     *
     * @default
     * 'translateY(0)'
     */
  @Input() showAtOrBelow?: number;

  /**
   * The selector of the element you want to listen the scroll event,
   * in case it is not the default browser scrolling element
   * (`document.scrollingElement` or `document.documentElement`).

   * For example [` .mat-sidenav-content`](https://stackoverflow.com/a/52931772/12954396)
   * if you are using Angular Material Sidenav
   */
  @Input() scrollingElementSelector = '';

  /**
   * Emitted when the element is hidden.
   */
  @Output() eventElementHidden = new EventEmitter<void>();

  /**
   * Emitted when the element is shown.
   */
  @Output() eventElementShown = new EventEmitter<void>();

  @Output()
  visibilityChange = new EventEmitter<boolean>();

  private unsubscribeNotifier = new Subject<void>();
  private lastScrollPosition = new BehaviorSubject<null | number>(null);
  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer2: Renderer2,
    @Inject(PLATFORM_ID) private platformId: string,
  ) { }

  ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    let elementToListenScrollEvent;
    let scrollingElement: HTMLElement;
    if (!this.scrollingElementSelector) {
      elementToListenScrollEvent = window;
      scrollingElement = this.getDefaultScrollingElement();
    } else {
      scrollingElement = document.querySelector(this.scrollingElementSelector) as HTMLElement;
      if (!scrollingElement) {
        // eslint-disable-next-line max-len
        console.error(`NgxHideOnScroll: @Input() scrollingElementSelector\nElement with selector: "${this.scrollingElementSelector}" not found.`);
        return;
      }
      elementToListenScrollEvent = scrollingElement;
    }

    const scroll$ = fromEvent(elementToListenScrollEvent, 'scroll').pipe(
      takeUntil(this.unsubscribeNotifier),
      throttleTime(13), // only emit every 50 ms
      map(() => {
        this.lastScrollPosition.next(scrollingElement.scrollTop);
        return scrollingElement.scrollTop;
      }), // get vertical scroll position
      pairwise(), // look at this and the last emitted element
      // compare this and the last element to figure out scrolling direction
      // eslint-disable-next-line max-len
      map(([y1, y2]): ScrollDirection => (y2 < y1 ? ScrollDirection.Up : ScrollDirection.Down)),
      // eslint-disable-next-line max-len
      share(), // share a single subscription to the underlying sequence in case of multiple subscribers

    );

    const scrollUp$ = scroll$.pipe(
      filter((direction) => direction === ScrollDirection.Up),
    );

    const scrollDown$ = scroll$.pipe(
      filter((direction) => direction === ScrollDirection.Down),
    );

    let scrollUpAction: () => void;
    let scrollDownAction: () => void;
    if (this.hideOnScroll === 'Up') {
      scrollUpAction = () => this.hideElement();
      scrollDownAction = () => this.showElement();
    } else {
      scrollUpAction = () => this.showElement();
      scrollDownAction = () => this.hideElement('down');
    }

    scrollUp$.subscribe(() => scrollUpAction());
    scrollDown$.subscribe(() => scrollDownAction());
  }

  ngOnDestroy(): void {
    this.unsubscribeNotifier.next();
    this.unsubscribeNotifier.complete();
  }

  private hideElement(direction?: string): void {
    const nativeElement = this.elementRef.nativeElement;
    if (this.classNameWhenHidden) {
      this.renderer2.addClass(nativeElement, this.classNameWhenHidden);
    } else {
      const lsd = this.lastScrollPosition.getValue();
      if (
        this.showAtOrBelow !== undefined &&
        direction === 'down' &&
        lsd &&
        lsd <= this.showAtOrBelow) {
        this.showElement();
      } else {
        this.renderer2.setStyle(nativeElement, this.propertyUsedToHide, this.valueWhenHidden);
      }
    }
    this.eventElementHidden.emit();
    this.visibilityChange.emit(false);
  }

  private showElement(): void {
    const nativeElement = this.elementRef.nativeElement;
    if (this.classNameWhenHidden) {
      this.renderer2.removeClass(nativeElement, this.classNameWhenHidden);
    } else {
      this.renderer2.setStyle(nativeElement, this.propertyUsedToHide, this.valueWhenShown);
    }
    this.eventElementShown.emit();
    this.visibilityChange.emit(true);
  }

  private getDefaultScrollingElement(): HTMLElement {
    return (document.scrollingElement || document.documentElement) as HTMLElement;
  }
}

enum ScrollDirection {
  Up = 'Up',
  Down = 'Down'
}
