<app-notification-image [notification]="notification"></app-notification-image>

<a class="text-container" (click)="onNotificationClick()">
  <span class="timestamp">{{ notification?.createdAt | date:'MMM d, y, h:mm a' }}</span>
  <span class="text" [matTooltip]="notification?.text ?? ''">{{notification?.text}}</span>
  <span class="subtext">{{notification?.subtext}}</span>
</a>

<div class="action-container">
  <button mat-icon-button (click)="onNotificationDeleteButtonClick()" [disabled]="loadingNotifications">
    <mat-icon>check_circle</mat-icon>
  </button>
  <small class="date">
  </small>
</div>