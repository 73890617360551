import { createReducer, on } from '@ngrx/store';

import * as NavActions from './actions';
import { NavLayoutState } from './state';

export const initialNavState: NavLayoutState = {
    bottom: {
      isCollapsed: true,
    },
};

export const navLayoutReducer = createReducer(
  initialNavState,
  on(NavActions.navLayoutBottomSetState, (state, { isCollapsed }) => ({
    ...state,
    bottom: {
      isCollapsed,
    },
  })),
);
