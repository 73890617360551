<div class="apps-container">
  <button mat-icon-button class="link" (click)="toggleAppsMenu()">
    <mat-icon fontIcon="apps" />
  </button>
  <div class="apps-menu mat-elevation-z3" id="apps-menu" [class.apps-menu--open]="appsMenuOpened()">
    <div class="apps-menu-grid">
      @for (app of menuItems; track app.name) {
        @if (!app.disabled) {
          <a mat-button class="link" [routerLink]="app.routerLink" routerLinkActive="active"
             [disabled]="app.disabled">
            <div class="app-container" [matTooltip]="app.name">
              @if (app.icon | startsWith : 'gth') {
                <mat-icon [svgIcon]="app.icon" />
              } @else {
                <mat-icon class="icon" [fontIcon]="app.icon" />
              }

              <span class="name">{{app.name}}</span>
            </div>
          </a>
        }
      }
    </div>
  </div>
</div>
