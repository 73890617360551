import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterModule } from '@angular/router';
import { GthUserModel } from '@sentinels/models';
import { SrvSafeWindowService } from '@sentinels/services/safe-window.service';
import { NgxHideOnScrollModule } from '@shared/directives/hide-on-scroll/ngx-hide-on-scroll.module';
import { APP_ROUTES } from '@shared/helpers';
import { SmallIconProperties, SmallNavIconComponent } from '@shared/layouts/nav-bar-layout/components/small-nav-icon/small-nav-icon.component';
import { MenuItemAuthCheckPipe } from '@shared/pipes/menu-item-auth-check.pipe';
import { BehaviorSubject } from 'rxjs';

import { DISCOVER_MENU, MANAGE_MENU } from '../../views/nav-bar.component';


@Component({
  selector: 'app-bottom-nav-bar',
  templateUrl: './bottom-nav-bar.component.html',
  styleUrls: ['./bottom-nav-bar.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    RouterModule,
    NgxHideOnScrollModule,
    SmallNavIconComponent,
    MatRippleModule,
    MenuItemAuthCheckPipe,
  ],
})
export class BottomNavBarComponent implements AfterViewInit {
  @Input()
  user: GthUserModel;

  @Input()
  loadingUser: boolean;

  @Input()
  showSelf: boolean;

  @Output()
  shown = new EventEmitter<boolean>();

  @ViewChild('bottomBar', { read: ElementRef }) bottomBar: ElementRef;
  offset = 0;
  isHidden = new BehaviorSubject(true);
  translateY$ = new BehaviorSubject('translateY(100%)');
  APP_ROUTES = APP_ROUTES;
  discoverMenu = DISCOVER_MENU;
  manageMenu = MANAGE_MENU;
  hasContent = false;
  barShown = true;

  iconProps: SmallIconProperties[] = [
    {
      name: 'Home',
      url: APP_ROUTES.Home,
      image: 'home',
      fragment: 'profile-personality',
    },

    {
      name: 'Discover',
      url: APP_ROUTES.DiscoverGames,
      image: 'discover',
    },

    {
      name: 'Manage',
      url: APP_ROUTES.Profile,
      image: 'user',
      fragment: 'profile-passions',
    },

    {
      name: 'Messages',
      url: APP_ROUTES.Messages,
      image: 'messages',
    },
  ];

  constructor(
    public ref: ElementRef,
    private router: Router,
    private safeWindow: SrvSafeWindowService,
  ) { }

  ngAfterViewInit(): void {
    this.hasContent = this.ref.nativeElement.children.length > 0;
  }

  isCurrentRouteSignUpOrLogin(): boolean {
    const currentRoute = this.router.url;
    return currentRoute === APP_ROUTES.SignUp || currentRoute === APP_ROUTES.Login ||
      currentRoute === APP_ROUTES.MoreInfo || currentRoute === APP_ROUTES.ForgotPassword;
  }

  isCurrentRouteDiscover(): boolean {
    const currentRoute = this.router.url;
    const discoverRoutes = [
      APP_ROUTES.DiscoverGames,
      APP_ROUTES.DiscoverPlayers,
      APP_ROUTES.DiscoverTeams,
    ];
    return discoverRoutes.includes(currentRoute);
  }

  isCurrentRouteManage(): boolean {
    const currentRoute = this.router.url;
    const manageRoutes = [
      APP_ROUTES.Profile,
      APP_ROUTES.Teams,
      APP_ROUTES.Settings,
    ];
    return manageRoutes.includes(currentRoute);
  }

  isDisplayed() {
    return this.getStyle().display !== 'none';
  }

  getStyle() {
    const element = this.bottomBar.nativeElement;
    return this.safeWindow.getComputedStyle(element);
  }

  getOffset() {
    const style = this.getStyle();
    const height = parseFloat(style.height);
    const translateY = parseFloat(style.transform?.split(',')[5]);
    return isNaN(translateY) ? height : height - translateY;
  }

  onVisibilityChange(val: boolean) {
    this.barShown = val;
    this.isHidden.next(!val);
    this.shown.emit(val);
  }
}
