@switch (notification?.type) {
  <!-- Event Notifications -->
  @case (NotificationType.EVENT_JOINER_APPROVED) {
    <ng-template [ngTemplateOutlet]="eventTmpl" />
  }
  @case (NotificationType.EVENT_JOINER_DENIED) {
    <ng-template [ngTemplateOutlet]="eventTmpl" />
  }
  @case (NotificationType.EVENT_JOINER_DROPPED) {
    <ng-template [ngTemplateOutlet]="eventTmpl" />
  }
  @case (NotificationType.EVENT_JOINER_PENDING_CREATOR) {
    <ng-template [ngTemplateOutlet]="eventTmpl" />
  }
  @case (NotificationType.EVENT_JOINER_WAITLIST) {
    <ng-template [ngTemplateOutlet]="eventTmpl" />
  }
  @case (NotificationType.EVENT_FULL) {
    <ng-template [ngTemplateOutlet]="eventTmpl" />
  }
  @case (NotificationType.EVENT_CANCELLED) {
    <ng-template [ngTemplateOutlet]="eventTmpl" />
  }
  @case (NotificationType.EVENT_UPCOMING) {
    <ng-template [ngTemplateOutlet]="eventTmpl" />
  }
  
  <!-- Badge Notifications -->
  @case (NotificationType.NEW_BADGE) {
    <picture class="badge">
      @if (notification?.badgeModel?.image) {
        <img
          [src]="notification?.badgeModel?.image"
          [alt]="notification?.badgeModel?.name"
        />
        <mat-icon fontIcon="question_mark" />
      }
    </picture>
  }
  
  <!-- Message Notifications -->
  @case (NotificationType.NEW_MESSAGE) {

  }
  
  <!-- Team Notifications -->
  @case (NotificationType.TEAM_REMINDER) {
    <ng-template [ngTemplateOutlet]="teamTmpl" />
  }
  @case (NotificationType.TEAM_JOINER_PENDING_JOINER) {
    <ng-template [ngTemplateOutlet]="teamTmpl" />
  }
  @case (NotificationType.TEAM_JOINER_PENDING_CREATOR) {
    <ng-template [ngTemplateOutlet]="teamTmpl" />
  }
  @case (NotificationType.TEAM_JOINER_APPROVED) {
    <ng-template [ngTemplateOutlet]="teamTmpl" />
  }
  @case (NotificationType.TEAM_JOINER_DENIED) {
    <ng-template [ngTemplateOutlet]="teamTmpl" />
  }
  @case (NotificationType.TEAM_JOINER_DROPPED) {
    <ng-template [ngTemplateOutlet]="teamTmpl" />
  }
}


<ng-template #eventTmpl>
  <picture>
    @if (notification?.joinerModel?.photoURL; as photoURL) {
      <img [src]="photoURL" [alt]="notification?.joinerModel?.displayName" />
    } @else {
      <ng-container [ngTemplateOutlet]="showUserIcon"></ng-container>
    }
  </picture>
</ng-template>

<ng-template #teamTmpl>
  <div class="icon-container">
    <app-sport-icon [sport]="notification?.teamModel?.sport" />
  </div>
</ng-template>

<ng-template #showUserIcon>
  <div class="icon-container">
    <mat-icon fontIcon="person" />
  </div>
</ng-template>
