import { createReducer, on } from '@ngrx/store';

import * as Notifications from './actions';
import { CurrentState, NotificationsState } from './state';

export const initialNotificationsState: NotificationsState = {
  notifications: {},
  state: CurrentState.Pending,
};

export const notificationReducer = createReducer(
  initialNotificationsState,

  on(Notifications.notificationLoad, (state) => ({
    ...state,
    state: CurrentState.Loading,
  })),

  on(Notifications.notificationLoadSuccess, (state, { notifications }) => ({
    ...state,
    state: CurrentState.Success,
    notifications: notifications.reduce((acc, curr) => ({
      ...acc,
      [curr.id]: curr,
    }), {}),
  })),

  on(Notifications.notificationLoadError, (state) => ({
    ...state,
    state: CurrentState.Error,
  })),
);
