<ng-container *ngIf="showSelf">
  <ng-container *ngIf="{user: user } as nav_bar">
    <mat-toolbar>
      <!-- Large Screen Items -->
      <div class="large-screen-container">
        <!-- Logo -->
        <a class="header-home-link" [routerLink]="[nav_bar.user ? APP_ROUTES.Home: APP_ROUTES.SignUp]">
          <img src="assets/logo-standing-fullcolor-small.webp" class="logo" />
        </a>

        <!-- Discover -->
        <a class="link messages left"
          [routerLink]="APP_ROUTES.DiscoverPlayers"
          [queryParams]="{context: 'participants'}"
          routerLinkActive="active">
          <mat-icon >people</mat-icon> <p class="nav-text">Find People</p>
        </a>
        <a class="link messages left"
          [routerLink]="APP_ROUTES.DiscoverTeams"
          [queryParams]="{context: 'teams'}"
          routerLinkActive="active">
          <mat-icon >groups</mat-icon> <p class="nav-text">Find Communities</p>
        </a>
        <a
          class="link messages left"
          [routerLink]="APP_ROUTES.DiscoverGames"
          [queryParams]="{context: 'events'}"
          routerLinkActive="active">
          <mat-icon>event</mat-icon> <p class="nav-text">Events</p>
        </a>
      </div>

      <div class="large-screen-container">
        <!-- Apps -->
        <app-apps-menu />

        <!-- Messages -->
        <a class="link messages" [routerLink]="APP_ROUTES.Messages" routerLinkActive="active">
          <mat-icon *ngIf="{numUnread: numUnreadMessages} as messages" [matBadge]="messages.numUnread"
            [matBadgeHidden]="!messages.numUnread" aria-hidden="false">message</mat-icon> <p class="nav-text">Messages</p>
        </a>

        <!-- Notifications -->
        <ng-container [ngTemplateOutlet]="notificationsBtnTmpl"></ng-container>
        <ng-template [ngTemplateOutlet]="avatarTemplate"></ng-template>
      </div>

      <!-- Small Screen Items -->
      <div class="small-screen-container">
        <div class="small-left">
          <a class="header-home-link" [routerLink]="[nav_bar.user ? APP_ROUTES.Home : APP_ROUTES.SignUp]">
            <img src="assets/logo-standing-fullcolor-small.webp" class="logo" />
          </a>
        </div>
        <div class="small-right">
          <ng-template class="avatar-template" [ngTemplateOutlet]="avatarTemplate"></ng-template>
          <!-- Notifications -->
          <ng-container class="notifications-template" [ngTemplateOutlet]="notificationsBtnTmpl"></ng-container>
          <!-- </div> -->
        </div>
      </div>
    </mat-toolbar>

    <!-- Discover Menu -->
    <mat-menu #discoverMatMenu="matMenu" class="discover-menu">
      <ng-container *ngFor="let item of discoverMenu">
        <a mat-menu-item class="link" [routerLink]="item.routerLink" routerLinkActive="active" *ngIf="!item.disabled"
          [disabled]="(item.authNeeded ? nav_bar.user === undefined : false) || item.disabled">
          <ng-container *ngIf="item.icon.startsWith('gth') else showIcon">
            <mat-icon [svgIcon]="item.icon"></mat-icon>
          </ng-container>
          <ng-template #showIcon>
            <mat-icon>{{item.icon}}</mat-icon>
          </ng-template>
          <span class="link-text">{{item.label}}</span>
        </a>
      </ng-container>
    </mat-menu>

    <!-- Notifications -->
    <ng-template #notificationsBtnTmpl>
     <!-- Reenable this when fixed. -->
      @if(disableNotifications){
        <button mat-icon-button class="link" (click)="onNotificationMenuClick(notificationsMenu)">
          <mat-icon [color]="hasUnreadNotifications() ? 'accent' : undefined" [matBadge]="notificationCount()"
            [matBadgeHidden]="!hasUnreadNotifications()" aria-hidden="false">notifications</mat-icon>
        </button>
      }
    </ng-template>

  </ng-container>
</ng-container>

<!-- Notifications Sidenav -->
<ng-template #notificationsMenu>
  <app-notifications-sidenav
    [notifications]="notifications()"
    [unreadNotifications]="filteredNotifications().unread"
    [readNotifications]="filteredNotifications().read"
    [hasNotifications]="hasNotifications()"
  ></app-notifications-sidenav>
</ng-template>

<!-- Loading -->
<ng-template #loadingTmpl>
  <div class="fx-auto fx-centered">
    <mat-spinner [diameter]="36"></mat-spinner>
  </div>
</ng-template>


<ng-template #avatarTemplate>
  <ng-container *ngIf="{user: user } as nav_bar">
    <div class="avatar-container link" [matMenuTriggerFor]="profileMatMenu">
      <ng-container *ngIf="loadingUser else showLargeScreenUserTmpl">
        <ng-template [ngTemplateOutlet]="loadingTmpl"></ng-template>
      </ng-container>
      <ng-template #showLargeScreenUserTmpl>
        <ark-user-avatar [src]="nav_bar.user?.photoURL" size="medium"/>
        <span class="name">{{nav_bar.user?.displayName ?? 'Guest'}}</span>
      </ng-template>
      <mat-icon>arrow_drop_down</mat-icon>
      <mat-menu #profileMatMenu="matMenu" xPosition="after">
        <a mat-menu-item [routerLink]="APP_ROUTES.Home" [disabled]="nav_bar.user === undefined">Home</a>
        <a mat-menu-item [routerLink]="APP_ROUTES.Profile" [disabled]="nav_bar.user === undefined">Profile</a>
        <a mat-menu-item [routerLink]="APP_ROUTES.Settings" [disabled]="nav_bar.user === undefined">Settings</a>
        <a mat-menu-item [routerLink]="APP_ROUTES.CreateGame" [disabled]="nav_bar.user === undefined">Create an Event</a>
        <mat-divider></mat-divider>
        <a mat-menu-item *ngIf="debugging" [routerLink]="APP_ROUTES.Admin">Admin</a>
        <button mat-menu-item (click)="onFeedbackButtonClick()">Submit Feedback</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="onAuthBtnClick(nav_bar.user)">{{nav_bar.user ? 'Log Out' : 'Log In'}}</button>
      </mat-menu>
    </div>
  </ng-container>
</ng-template>
