import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GthNotificationModel } from '@sentinels/models';
import { SportIconModule } from '@shared/components/sport-icon/sport-icon.module';

import { NotificationImageComponent } from './notification-image/notification-image.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    NotificationImageComponent,
    SportIconModule,
    DatePipe,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input()
  notification?: GthNotificationModel;

  @Input()
  loadingNotifications = false;

  @Output()
  notificationClick = new EventEmitter();

  @Output()
  notificationDelete = new EventEmitter();

  onNotificationClick() {
    this.notificationClick.emit();
  }

  onNotificationDeleteButtonClick() {
    this.notificationDelete.emit();
  }
}
