import { Injectable } from '@angular/core';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';

import { environment } from '../../../../../../environments/environment';

export interface GthFeedback {
  feedback: string;
  rating: number;
  feedbackType: 'bug' | 'request' | 'general';
}

@Injectable({
  providedIn: 'root',
})
export class GthFeedbackService {
  async submitFeedback(contract: GthFeedback) {
    try {
      const uri = this.getFunctionUri(`submit`);
      const callable = httpsCallableFromURL(getFunctions(), uri);

      const { feedback, feedbackType, rating } = contract;

      const result = (await callable({ feedback, feedbackType, rating })).data as any;
      return result;
    } catch (error) {
      console.error('Error:', error);
      return undefined;
    }
  }

  private getFunctionUri(functionName: string) {
    const env = this.getEnviroment();

    // eslint-disable-next-line max-len
    return `https://us-central1-gametimehero-${env}.cloudfunctions.net/feedback-triggers-${functionName}`;
  }

  private getEnviroment(): string {
    return environment.envName!;
  }
}
