import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { GthBadgeModel } from '@sentinels/models';

@Component({
  selector: 'app-new-badge-notification',
  templateUrl: './new-badge-notification.component.html',
  styleUrls: ['./new-badge-notification.component.scss'],
  imports: [
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewBadgeNotificationComponent implements OnInit {
  public badge?: GthBadgeModel;
  public showBadge = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: {badge: GthBadgeModel}) {}
  ngOnInit() {
    this.badge = this.data.badge;
  }

  public onCheckItOutBtnClick() {
    this.showBadge = true;
  }
}
