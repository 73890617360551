import { AsyncPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, effect, ElementRef, Input, OnDestroy, OnInit, Signal, signal, ViewChild } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import {
  APP_STATE,
  AuthLogout,
  CurrentState,
} from '@gth-legacy';
import { Store } from '@ngrx/store';
import { GthUserModel } from '@sentinels/models';
import { DebuggingService } from '@sentinels/services/firebase/debugging.service';
import { SrvSafeStorageService } from '@sentinels/services/safe-storage.service';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { notificationLoad } from '@sentinels/state/features/notifications/actions';
import { notificationReducer } from '@sentinels/state/features/notifications/reducers';
import { getNotifications, getNotificationState } from '@sentinels/state/features/notifications/selectors';
import { APP_ROUTES } from '@shared/helpers';
import { Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

import { MessageService } from '../../../../features/messages/services/message.service';
import { BottomNavBarComponent } from '../components/bottom-nav-bar/bottom-nav-bar.component';
import { SmallIconProperties } from '../components/small-nav-icon/small-nav-icon.component';
import { TopNavBarComponent } from '../components/top-nav-bar/top-nav-bar.component';
import { navLayoutBottomSetState } from '../state/actions';
import { navLayoutReducer } from '../state/reducers';

export interface NavBarOptions {
  pageName?: string;
  fullWidth?: boolean;
}
export interface NavMenuItem {
  label: string;
  icon: string;
  authNeeded: boolean;
  routerLink: string[];
  params?: { [key: string]: string };
  fragment?: string;
  disabled: boolean;
}

export const DISCOVER_MENU: NavMenuItem[] = [
  {
    label: 'Teams and Groups',
    icon: 'group',
    authNeeded: false,
    routerLink: [APP_ROUTES.DiscoverTeams],
    params: { context: 'teams' },
    disabled: false,
  },
  {
    label: 'Available Participants',
    icon: 'person',
    authNeeded: false,
    routerLink: [APP_ROUTES.DiscoverPlayers],
    params: { context: 'participants' },
    disabled: false,
  },
  {
    label: 'Events & Pickup Games',
    icon: 'games',
    authNeeded: false,
    routerLink: [APP_ROUTES.DiscoverGames],
    params: { context: 'events' },
    disabled: false,
  },
];

export const MANAGE_MENU: NavMenuItem[] = [
  {
    label: 'My Teams & Groups',
    icon: 'group',
    authNeeded: true,
    routerLink: [APP_ROUTES.Teams],
    disabled: false,
  },
  {
    label: 'My Events',
    icon: 'games',
    authNeeded: true,
    routerLink: [APP_ROUTES.Profile],
    disabled: false,
    fragment: 'upcoming',
  },
  {
    label: 'My Availability',
    icon: 'schedule',
    authNeeded: true,
    routerLink: [APP_ROUTES.Settings],
    disabled: false,
    params: { tab: 'availability' },
  },
];

@Component({
  selector: 'app-nav-bar-layout',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    MatToolbarModule, MatButtonModule,
    MatMenuModule, MatIconModule,
    MatBadgeModule, MatTooltipModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    TopNavBarComponent,
    BottomNavBarComponent,
    AsyncPipe,
  ],
})
export class NavBarLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('bottomBar', { static: true }) bottomBar: BottomNavBarComponent;
  @ViewChild('topNavBar', { read: ElementRef }) topNavBar!: ElementRef;
  @ViewChild('contentWrapper') contentWrapper!: ElementRef;
  @Input()
  showTopBar = true;

  @Input()
  showBottomBar = false;

  protected readonly APP_ROUTES = APP_ROUTES;

  user$: Observable<GthUserModel | null> = of(null);
  loadingUser$: Observable<boolean> = of(true);
  userPhotoError?: ErrorEvent;

  notifications = this.store.selectSignal(getNotifications);
  notificationState = this.store.selectSignal(getNotificationState);
  user = this.store.selectSignal(selectUser);

  @Input() navBarOptions: NavBarOptions = {
    pageName: 'Unknown',
    fullWidth: false,
  };
  numUnreadMessages$?: Observable<number>;
  debugging = false;
  iconProps: SmallIconProperties[] = [
    {
      name: 'Home',
      url: APP_ROUTES.Profile,
      image: 'home',
      fragment: 'profile-personality',
    },

    {
      name: 'Find...',
      url: APP_ROUTES.DiscoverGames,
      image: 'discover',
    },

    {
      name: 'My Teams & Groups',
      url: APP_ROUTES.Teams,
      image: 'teams-groups',
    },

    {
      name: 'My Events',
      url: APP_ROUTES.Profile,
      image: 'my-events',
      fragment: 'profile-passions',
    },
    {
      name: 'My Availability',
      url: APP_ROUTES.Settings,
      image: 'my-availibility',
      params: { tab: 'availability' },
    },

    {
      name: 'Messages',
      url: APP_ROUTES.Messages,
      image: 'messages',
    },
  ];

  private subscriptions = new Subscription();

  constructor(
    private debuggingService: DebuggingService,
    private safeStorage: SrvSafeStorageService,
    public router: Router,
    private messageService: MessageService,
    private store: Store<APP_STATE>,
  ) {
    effect(() => {
      if (this.user() && this.notificationState() === CurrentState.Pending) {
        this.store.dispatch(notificationLoad({ userId: this.user().uid }));
      }
    }, { allowSignalWrites: true }); // Todo(srevier): this shouldn't be neccessary
  }

  async ngOnInit() {
    this.store.addReducer('navLayout', navLayoutReducer);
    this.store.addReducer('notifications', notificationReducer);
    this.user$ = this.store.select(selectUser).pipe(shareReplay());
    this.loadingUser$ = this.store
      .select((state) => state.authFeature.state === CurrentState.Loading);

    this.numUnreadMessages$ = this.messageService.getNonTeamConversations$().pipe(
      map((conversations) => {
        let n = 0;
        for (const conversation of conversations) {
          conversation.messages.forEach((m) => !m.isRead ? n++ : undefined);
        }
        return n;
      }),
    );

    const user = await this.user$.toPromise();
    const storedTimestamp = this.safeStorage.getItem('heroAdminTimestamp');

    if (storedTimestamp) {
      const storedTime = parseInt(storedTimestamp, 10);
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - storedTime;
      const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;

      if (timeDifference < twentyFourHoursInMilliseconds) {
        this.debugging = true;
      } else {
        this.safeStorage.removeItem('heroAdminTimestamp');
        this.debugging = false;
      }
    }

    if (user) {
      const adminDebugging = await this.debuggingService.getDebugging(user.id);
      this.debugging = storedTimestamp ? true : adminDebugging?.debug ?? false;
    }
  }

  ngAfterViewInit(): void {
    const sub$ = this.bottomBar.isHidden.pipe(distinctUntilChanged(), map((status) => {
      this.store.dispatch(navLayoutBottomSetState({ isCollapsed: status }));
    }));

    this.subscriptions.add(sub$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onLogout() {
    if (!this.user()) return;
    this.store.dispatch(AuthLogout());
  }
  onLogin() {
    this.router.navigate(['/']);
  }


  // onRefreshTeams() {
  //   this.notificationsService.refreshTeams();
  // }

  // onNotificationsRefresh() {
  //   this.notificationsService.refresh();
  // }
}
