import { computed, Injectable, signal, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface SidenavServiceItem {
  title: string;
  portalContent: TemplateRef<unknown>;
}

@Injectable({
  providedIn: 'root',
})
export class AppSidenavService {
  ctrl = signal<SidenavServiceItem>(undefined);

  public sidenavTitle = computed(() => this.ctrl().title);
  public sidenavContent = computed(() => this.ctrl().portalContent);

  clear() {
    this.ctrl.set(undefined);
  }
}
