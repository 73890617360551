import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, inject, input, Output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { GthNotificationModel, GthUserModel } from '@sentinels/models';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { notificationClear, notificationRead, notificationReadAll, notificationRemove } from '@sentinels/state/features/notifications/actions';
import { PwaNotificationsService } from '@shared/services/pwa-notifications.service';

import { APP_STATE } from '../../../../../../../../gth-legacy/src/public-api';
import { NotificationDialogService } from '../../services/notification-dialog/notification-dialog.service';
import { NotificationComponent } from './notification/notification.component';

export type NotificationObj = { [key: string]: GthNotificationModel };

@Component({
  selector: 'app-notifications-sidenav',
  templateUrl: './notifications-sidenav.component.html',
  styleUrls: ['./notifications-sidenav.component.scss'],
  imports: [
    MatButtonModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NotificationComponent,
    AsyncPipe,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsSidenavComponent {
  notifications = input<{ [key: string]: GthNotificationModel }>({});
  unreadNotifications = input<GthNotificationModel[]>([]);
  readNotifications = input<GthNotificationModel[]>([]);
  hasNotifications = input<boolean>(false);

  unreadNotificationCount = computed(() => this.unreadNotifications().length);
  readNotificationCount = computed(() => this.readNotifications().length);

  filter = signal<'read' | 'unread'>('unread');

  @Output()
  refresh = new EventEmitter<void>();

  @Output()
  refreshTeams = new EventEmitter<void>();

  loadingNotifications = false;

  private store = inject(Store<APP_STATE>);
  private notificationDialogService = inject(NotificationDialogService);
  private pwaNotifications = inject(PwaNotificationsService);

  user = this.store.selectSignal(selectUser);

  displayPwaPrompt$ = this.pwaNotifications.enabled$;

  updateFilter(filterBy: 'read' | 'unread') {
    this.filter.set(filterBy);
  }

  onPwaPromptButtonClick() {
    this.pwaNotifications.request(this.user());
  }

  onReadAllBtnClick() {
    this.store.dispatch(notificationReadAll({ userId: this.user().uid }));
  }

  onNotificationClick(notification: GthNotificationModel, user: GthUserModel) {
    this.store.dispatch(notificationRead({
      userId: user.uid,
      notificationId: notification.id,
    }));

    this.notificationDialogService.openNotificationDialog(notification, user);
  }

  onNotificationDeleteButtonClick(notification: GthNotificationModel) {
    this.store.dispatch(notificationRemove({
      userId: this.user().uid,
      notificationId: notification.id,
    }));
  }

  onClearAllBtnClick() {
    this.store.dispatch(notificationClear({ userId: this.user().uid }));
  }
}
