<div class="layout-wrapper">
  <app-top-nav-bar #topNavBar [numUnreadMessages]="numUnreadMessages$ | async" [loadingUser]='loadingUser$ | async'
    [user]="user$ | async" [showSelf]="showTopBar" [notifications]="notifications()" (logout)="onLogout()" />

  <div class="content-wrapper app-scrolling-content" #contentWrapper [class.full-width]="navBarOptions.fullWidth">
    <ng-content />
  </div>

  <app-bottom-nav-bar #bottomBar ngx-sticky-boundary [user]="user$ | async" [showSelf]="showBottomBar"
    [loadingUser]='loadingUser$ | async'>
    <ng-content select="[slot=fab]" />
  </app-bottom-nav-bar>
</div>