import { DatePipe, formatDate, JsonPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { NotificationType } from '@index/enums';
import { GthNotificationModel } from '@sentinels/models';

import { ArkUserAvatarComponent } from '../../../../../../ark/src/public-api';

export enum EditPaymentDialogResponseType {
  Approve = 1,
  Disapprove = 2,
  Message = 3,
}

export interface EditPaymentDialogResponse {
  responseType: EditPaymentDialogResponseType;
}

export interface NotificationDialogContract {
  notification: GthNotificationModel;
}

interface NotificationInfo {
  type: NotificationType;
  acceptText?: string;
  title: string;
  creatorTitle?: string;
  subtitle: string;
  creatorSubtitle?: string;
}

const NOTIFICATIONS: NotificationInfo[] = [
  {
    type: NotificationType.EVENT_JOINER_APPROVED,
    title: 'Your request has been Approved',
    creatorTitle: 'Player has been Approved',
    subtitle: 'Your request has been approved to participate in the event.',
    creatorSubtitle: '${displayName} has been approved to participate in the event.',
  },
  {
    type: NotificationType.EVENT_JOINER_DENIED,
    title: 'Your request has been Denied',
    creatorTitle: 'Player has been Denied',
    subtitle: 'Your request has been denied to participate in the event.',
    creatorSubtitle: '${displayName} has been denied to participate in the event.',
  },
  {
    type: NotificationType.EVENT_JOINER_DROPPED,
    title: 'You have been Dropped',
    creatorTitle: 'Player has Dropped',
    subtitle: 'You have been dropped from the event.',
    creatorSubtitle: '${displayName} has dropped from the event.',
  },
  {
    type: NotificationType.EVENT_JOINER_PENDING_CREATOR,
    title: 'Your request is Awaiting Approval',
    creatorTitle: 'Player is Awaiting Approval',
    subtitle: 'Your request is awaiting approval to participate in the event.',
    creatorSubtitle: '${displayName} is awaiting approval to participate in the event.',
  },
  {
    type: NotificationType.EVENT_JOINER_WAITLIST,
    title: 'Your request is Awaiting Approval',
    creatorTitle: 'Player is Awaiting Approval',
    subtitle: 'Your request is awaiting approval to participate in the event.',
    creatorSubtitle: '${displayName} is awaiting approval to participate in the event.',
  },
  {
    type: NotificationType.EVENT_JOINER_PENDING_JOINER,
    title: 'Player wants you to join them',
    subtitle: 'Player is awaiting approval to join the event.',
    acceptText: 'View Game',
  },
  {
    type: NotificationType.EVENT_CANCELLED,
    title: 'Event Cancelled',
    subtitle: 'The event has been cancelled.',
    creatorSubtitle: 'Your event has been cancelled.',
  },
  {
    type: NotificationType.EVENT_FULL,
    title: 'Event Full',
    subtitle: 'The event is full.',
    creatorSubtitle: 'Your event is full.',
  },
  /** Team Notifications */
  {
    type: NotificationType.TEAM_JOINER_APPROVED,
    title: 'Your request has been Approved',
    creatorTitle: 'Player has been Approved',
    subtitle: 'Go to my Teams & Groups to view your new team',
    creatorSubtitle: 'Go to my Teams & Groups to view your team',
  },
  {
    type: NotificationType.TEAM_JOINER_DENIED,
    title: 'Team denied your request to Join',
    creatorTitle: 'Player has been Denied',
    subtitle: '',
    creatorSubtitle: '',
  },
  {
    type: NotificationType.TEAM_JOINER_DROPPED,
    title: 'You have been Dropped',
    creatorTitle: 'Player has Dropped',
    subtitle: '',
  },
  {
    type: NotificationType.TEAM_JOINER_PENDING_CREATOR,
    title: 'Your request is Awaiting Approval',
    creatorTitle: 'Player wants to Join your team',
    subtitle: 'Your request is awaiting approval to join the team.',
    creatorSubtitle: 'Player is awaiting approval to join your team.',
  },
  {
    type: NotificationType.TEAM_JOINER_PENDING_JOINER,
    title: 'Team wants you to join them',
    creatorTitle: 'Your request is Awaiting Approval',
    subtitle: 'Team is awaiting approval to join the team.',
    creatorSubtitle: 'Your request is awaiting approval to join the team.',
  },
  {
    type: NotificationType.TEAM_REMINDER,
    title: 'Reminder: Team has a game coming up',
    subtitle: '${eventName} starts on ${eventDate} at ${eventTime}.',
  },
];


@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
  imports: [
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    NgTemplateOutlet,
    MatDividerModule,
    DatePipe,
    ArkUserAvatarComponent,
    JsonPipe,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: NotificationDialogContract,
  ) { }

  get NotificationType() {
    return NotificationType;
  }

  get notification() {
    return this.data?.notification ?? undefined;
  }

  get type() {
    return this.notification?.type ?? '';
  }

  get recipient() {
    return this.notification.recipient;
  }

  get title() {
    const notification = this.notificationInfo;
    if (!notification) {
      return 'New Notification';
    }
    if (this.recipient === 'creator' && notification.creatorTitle) {
      return notification.creatorTitle;
    }
    return notification.title;
  }

  get subtitle() {
    const joiner = this.joiner;
    const displayName = joiner?.displayName ?? 'Unknown Player';
    const notification = this.notificationInfo;
    if (!notification) {
      return '';
    }
    if (this.recipient === 'creator' && notification.creatorSubtitle) {
      return notification.creatorSubtitle.replace('${displayName}', displayName);
    }
    if (notification.subtitle.includes('${eventName}')) {
      return notification.subtitle
        .replace('${eventName}', this.event?.title)
        .replace('${eventDate}', formatDate(this.event?.dateStart, 'longDate', 'en-US'))
        .replace('${eventTime}', formatDate(this.event?.dateStart, 'shortTime', 'en-US'));
    }
    return notification.subtitle.replace('${displayName}', displayName);
  }

  get acceptText() {
    const notification = this.notificationInfo;
    if (!notification) {
      return '';
    }
    return notification.acceptText ?? 'Send Message';
  }

  get team() {
    if (!this.notification || !this.notification.teamModel) {
      return undefined;
    }
    return this.notification.teamModel;
  }

  get event() {
    if (!this.notification || !this.notification.eventModel) {
      return undefined;
    }
    return this.notification.eventModel;
  }

  get joiner() {
    if (!this.notification || !this.notification.joinerModel) {
      return undefined;
    }
    return this.notification.joinerModel;
  }

  get needsApproval() {
    const needsApprovalTypes: (NotificationType | '')[] = [
      NotificationType.EVENT_JOINER_PENDING_CREATOR,
      NotificationType.EVENT_JOINER_WAITLIST,
      NotificationType.TEAM_JOINER_PENDING_JOINER,
      NotificationType.TEAM_JOINER_PENDING_CREATOR,
    ];
    return needsApprovalTypes.includes(this.type);
  }

  get eventNotification() {
    return this.type.startsWith('event');
  }

  private get notificationInfo() {
    return NOTIFICATIONS.find((n) => n.type === this.type);
  }

  onDisapproveButtonClick() {
    const response: EditPaymentDialogResponse = {
      responseType: EditPaymentDialogResponseType.Disapprove,
    };
    this.dialogRef.close(response);
  }

  onApproveButtonClick() {
    const response: EditPaymentDialogResponse = {
      responseType: EditPaymentDialogResponseType.Approve,
    };
    this.dialogRef.close(response);
  }

  onSendMessage() {
    const response: EditPaymentDialogResponse = {
      responseType: EditPaymentDialogResponseType.Message,
    };
    this.dialogRef.close(response);
  }

  onCancelButtonClick() {
    this.dialogRef.close(false);
  }
}
