import { Pipe, PipeTransform } from '@angular/core';
import { GthUserModel } from '@sentinels/models';
import { NavMenuItem } from '@shared/layouts/nav-bar-layout/views/nav-bar.component';

@Pipe({
  name: 'menuItemAuthCheck',
  standalone: true,
})
export class MenuItemAuthCheckPipe implements PipeTransform {
  transform(item: NavMenuItem, user: GthUserModel): boolean {
    return (item.authNeeded ? user === undefined : false) || item.disabled;
  }
}
