import { GthNotificationModel } from '@sentinels/models';

export enum CurrentState {
    Loading = 'loading',
    Pending = 'pending',
    Success = 'success',
    Error = 'error',
}

export interface NotificationsState {
  notifications: { [name: string]: GthNotificationModel };
  state: CurrentState;
};
