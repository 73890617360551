import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'startsWith',
  standalone: true,
})
export class StartsWithPipe implements PipeTransform {
  transform(value: string, pre: string): any {
    return value.startsWith(pre);
  }
}
