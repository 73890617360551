import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NotificationsState } from './state';

export const selectNotificationsState = createFeatureSelector<NotificationsState>(
  'notifications',
);

export const getNotifications = createSelector(
  selectNotificationsState,
  (state) => state.notifications,
);

export const getNotificationState = createSelector(
  selectNotificationsState,
  (state) => state.state,
);
