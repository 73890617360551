import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GthEventItemModel, GthEventRatingModel, GthUserModel } from '@sentinels/models';
import { SrvApiService } from '@sentinels/services/api.service';
import { UserService } from '@sentinels/services/firebase/user.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface EventRatingContract {
  game: GthEventItemModel;
  user: GthUserModel;
}

export interface EventRatingResponse {
  ratings: EventRatingPlayerResponse[];
}

export interface EventRatingPlayerResponse {
  player: string;
  note?: string;
  ratings?: number[];
}

@Component({
  selector: 'app-event-rating',
  templateUrl: './event-rating.component.html',
  styleUrls: ['./event-rating.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EventRatingComponent implements OnInit {
  ratingsSelection = new SelectionModel<number>(true, []);
  eventRatings: GthEventRatingModel[] = [];

  get event() {
    return this.data.game;
  }

  get participants() {
    return this.data.game.participants;
  }

  get maxRatingsSelected() {
    return this.ratingsSelection.selected.length > 2;
  }

  get index() {
    return this.indexSubject.getValue();
  }

  participantCount = 1;
  participant$?: Observable<GthUserModel | undefined>;
  imageError = false;
  noParticipants = false;
  private participants$?: Observable<GthUserModel[]> | undefined;
  private indexSubject = new BehaviorSubject<number>(0);
  private ratings: EventRatingPlayerResponse[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: EventRatingContract,
    private dialogRef: MatDialogRef<EventRatingComponent>,
    private api: SrvApiService,
    private usersService: UserService,
  ) {}

  async ngOnInit() {
    const index$ = this.indexSubject.asObservable();
    if (this.data.game.participants.length === 0) {
      this.noParticipants = true;
    }
    const participants = this.data.game.participants.map((p) => p.player);
    const requests$ = participants
      .filter((p) => p !== this.data.user.uid)
      .map((p) => this.usersService.getUserById$(p));
    this.participants$ = combineLatest(requests$).pipe(
      map((users) => {
        // Filter out deleted users
        return users.filter((x) => {
          return x !== undefined;
        }) as GthUserModel[];
      }),
    );
    this.participant$ = combineLatest([index$, this.participants$]).pipe(
      map(([index, users]) => {
        this.participantCount = users.length;
        this.imageError = false;
        if (!users || !index || index > users.length) {
          return undefined;
        }
        return users[index];
      }),
    );

    this.eventRatings = await this.api.eventRatings.listAsync();
  }

  ratePlayer(player: GthUserModel, ratings: number[], note: string) {
    this.ratings.push({
      player: player.uid,
      ratings,
      note,
    });
    if (this.index + 1 === this.participantCount) {
      this.dialogRef.close({
        ratings,
      });
      return;
    }
    this.indexSubject.next(this.index + 1);
  }
}
