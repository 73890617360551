import { NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NotificationType } from '@index/enums/notifications';
import { GthNotificationModel } from '@sentinels/models';
import { SportIconModule } from '@shared/components/sport-icon/sport-icon.module';

@Component({
  selector: 'app-notification-image',
  templateUrl: './notification-image.component.html',
  styleUrls: ['./notification-image.component.scss'],
  imports: [
    SportIconModule,
    MatIconModule,
    NgTemplateOutlet,
  ],
  standalone: true,
})
export class NotificationImageComponent {
  @Input() notification?: GthNotificationModel;
  protected readonly NotificationType = NotificationType;
}
