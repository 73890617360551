<button mat-icon-button class="close-btn" mat-dialog-close><mat-icon>close</mat-icon></button>

<h1>You have earned a new badge!</h1>

<picture>
  <img [src]="badge?.image" [style.filter]="showBadge ? 'none' : undefined" />
  @if (!showBadge) {
    <mat-icon>question_mark</mat-icon>
  }
</picture>

<div class="actions">
  <button mat-flat-button color="primary" [disabled]="showBadge"
          (click)="onCheckItOutBtnClick()">
    Check it out!
  </button>
  <button mat-button class="skip-btn" [disabled]="showBadge" mat-dialog-close>
    SKIP FOR NOW
  </button>
</div>
