<button mat-icon-button class="close-btn" (click)="onCancelButtonClick()">
  <mat-icon>close</mat-icon>
</button>

<div class="header">
  <h3 class="dialog-title">{{ title }}</h3>
  <p class="subtext">{{ subtitle }}</p>
</div>

<div mat-dialog-content class="dialog-content">
  <div class="details-container">
    @if (type.startsWith('event')) {
      <div class="event-details">
        <h3 matLine class="event-title">{{ event?.title }}</h3>
        <p matLine><b>Type:</b> {{ event?.gameType }}</p>
        <p matLine><b>Date:</b> {{ event?.dateStart | date:'long' }}</p>
        <p matLine><b>Location:</b> {{ event?.location?.formattedAddress }}</p>
      </div>
    } @else {
      <div class="team-details">
        <h3 matLine class="team-name">{{ team?.name }}</h3>
        <p matLine><b>Sport:</b> {{ team?.sport }}</p>
        <p matLine><b>Player Count:</b> {{ team?.roster?.length }}</p>
        <p matLine><b>Location:</b> {{ team?.location?.formattedAddress }}</p>
      </div>
    }

    @if (joiner) {
      <mat-divider></mat-divider>
  
      <!-- Player Details -->
      <div class="players-detail">
        <h3 class="subheader">Players</h3>
        <div class="player">
          <ark-user-avatar [src]="joiner.photoURL"></ark-user-avatar>
  
          <div class="text-container">
            <h3 class="joiner-title">{{ joiner.displayName }}</h3>
            <p class="joiner-gender">
              {{ joiner.gender }} 
              @if (joiner.pronoun) {
                {{ ' - ' + joiner.pronoun }}
              }
            </p>
          </div>
  
          @if (needsApproval) {
            <button mat-button (click)="onSendMessage()">
              <div class="send-message-container">
                <div><mat-icon>message</mat-icon></div>
                <span>Send a message</span>
              </div>
            </button>
          }
        </div>
      </div>
    }

  </div>
</div>

<!-- Dialog Actions -->
@if (!needsApproval) {
  <div class="send-message-row" mat-dialog-actions>
    @if (eventNotification) {
      <button mat-flat-button color="primary" class="dialog-button" (click)="onSendMessage()">
        {{ acceptText }}
      </button>
    } @else {
      <button mat-flat-button color="primary" class="dialog-button" (click)="onCancelButtonClick()">
        Okay
      </button>
    }
  </div>
} @else {
  <div mat-dialog-actions class="dialog-actions">
    <button mat-stroked-button class="dialog-button" (click)="onDisapproveButtonClick()" color="primary">Deny</button>
    <button mat-flat-button class="dialog-button" (click)="onApproveButtonClick()" color="primary">Approve</button>
  </div>
}
