import { NgModule } from '@angular/core';

import { NgxHideOnScrollDirective } from './ngx-hide-on-scroll.directive';


@NgModule({
  declarations: [NgxHideOnScrollDirective],
  imports: [],
  exports: [NgxHideOnScrollDirective],
})
export class NgxHideOnScrollModule { }
