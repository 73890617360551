@if (hasContent) {
<div class="fab-container" [class.--above-bar]="barShown">
  <ng-content></ng-content>
</div>
}

<mat-toolbar class="bottom-bar" *ngIf="!isCurrentRouteSignUpOrLogin()" #bottomBar
  scrollingElementSelector=".mat-sidenav-content" appHideOnScroll [propertyUsedToHide]="'transform'"
  [showAtOrBelow]="30" [valueWhenHidden]="(translateY$ | async)" [valueWhenShown]="'translateY(0%)'"
  (visibilityChange)="onVisibilityChange($event)">
  @for (prop of iconProps; track prop.name) {
    @switch (prop.name) {
      @case ('Discover') {
        <button 
          matRipple
          [matMenuTriggerFor]="discoverMatMenuBottom"
          class="discover link"
          [class.active]="isCurrentRouteDiscover()"
        >
          <app-small-nav-icon [iconProps]="prop"></app-small-nav-icon>
        </button>
      }
      @case ('Manage') {
        <button 
          matRipple
          [matMenuTriggerFor]="eventsTeamsBottomMenu"
          class="discover link"
          [class.active]="isCurrentRouteManage()"
        >
          <app-small-nav-icon [iconProps]="prop"></app-small-nav-icon>
        </button>
      }
      @default {
        <a 
          matRipple 
          [routerLink]="prop.url"
          routerLinkActive="active"
          [fragment]="prop.fragment"
          [queryParams]="prop.params"
          class="no-dec"
        >
          <app-small-nav-icon [iconProps]="prop"></app-small-nav-icon>
        </a>
      }
    }
  }
</mat-toolbar>

<!-- Discover Menu -->
<mat-menu #discoverMatMenuBottom="matMenu" class="discover-menu" xPosition="after">
  @if (user; as user) {
    @for (item of discoverMenu; track item.label) {
      <a
        mat-menu-item
        class="link"
        [routerLink]="item.routerLink"
        routerLinkActive="active"
        *ngIf="!item.disabled"
        [disabled]="item | menuItemAuthCheck: user"
        [queryParams]="item.params"
      >
        @if (item.icon.startsWith('gth')) {
          <mat-icon [svgIcon]="item.icon"></mat-icon>
        } @else {
          <mat-icon>{{item.icon}}</mat-icon>
        }
        <span class="link-text">{{item.label}}</span>
      </a>
    }
  }
</mat-menu>

<!-- Manage Menu -->
<mat-menu #eventsTeamsBottomMenu="matMenu" xPosition="after">
  @if (user) {
    @for (item of manageMenu; track item.label) {
      <a 
        mat-menu-item
        class="link"
        [routerLink]="item.routerLink"
        routerLinkActive="active"
        *ngIf="!item.disabled"
        [disabled]="item | menuItemAuthCheck: user"
        [queryParams]="item.params"
        [fragment]="item.fragment"
      >
        <mat-icon>{{item.icon}}</mat-icon>
        <span class="link-text">{{item.label}}</span>
      </a>
    }
  }
</mat-menu>