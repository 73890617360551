import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from '@environments/environment';
import { GthDebugging, GthDebuggingUser } from '@gth-legacy';
import { DBUtil } from '@index/utils/db-utils';
import { GthUserModel } from '@sentinels/models';
import { FirestoreService } from '@sentinels/services/core/firebase.service';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { first } from 'rxjs/operators';

import { SrvBetaService } from './beta.service';

@Injectable({ providedIn: 'root' })
export class DebuggingService extends FirestoreService<GthDebugging> {
  protected basePath = 'admin_debugging';

  constructor(
    private beta: SrvBetaService,
    firestore: AngularFirestore,
  ) {
    super(firestore);
  }

  async getUsers(): Promise<GthUserModel[]> {
    const userItems = await this.firestore.collection(DBUtil.User)
      .get()
      .toPromise();
    if (userItems.empty) return [];
    const users = await userItems.docs.map(async (doc) => {
      const data = {
        uid: doc.id,
        ...doc.data() as any,
      };
      const isAdmin = await this.isAdminUser(doc.id);
      const user = new GthUserModel(doc.id, data);
      const betaItems = await this.beta.getUserBetas(user.id);
      const metadata = {
        admin: isAdmin,
      };
      betaItems.forEach((b) => {
        metadata[b] = 'ON';
      });
      user.metadata = metadata;
      return user;
    });
    const allUsers = await Promise.all(users);
    return allUsers
      .filter((u) => u.displayNameFallback)
      .sort((a, b) => a.displayNameFallback.localeCompare(b.displayNameFallback));
  }

  async getDebugging(userId: string): Promise<GthDebugging> {
    const playerRoles = await this.firestore.collection(
      this.basePath,
      (ref) => ref
        .where('userId', '==', userId),
    ).get().pipe(first()).toPromise();

    const docs = playerRoles.docs;

    if (!docs || docs.length === 0) {
      return undefined;
    }

    const doc = docs[0];

    return doc.data() as GthDebugging;
  }

  async getTestingUsers(): Promise<GthDebuggingUser[]> {
    try {
      const uri = this.getFunctionUri(`getTestUsers`);

      const callable = httpsCallableFromURL(getFunctions(), uri);

      const result = (await callable()).data as unknown as GthDebuggingUser[];

      console.log('Debugging users returned', result);

      return result;
    } catch (error) {
      console.error('Error:', error);

      return [];
    }
  }

  async deleteTestingUser(id: string) {
    try {
      const uri = this.getFunctionUri(`deleteTestingUser`);

      const callable = httpsCallableFromURL(getFunctions(), uri);

      const result = (await callable({ id })).data as any;

      return result;
    } catch (error) {
      console.error('Error:', error);

      return undefined;
    }
  }

  async sendEmail(header: string, recipientIds: string[], eventId: string) {
    try {
      const uri = this.getFunctionUri(`sendEmail`);

      const callable = httpsCallableFromURL(getFunctions(), uri);

      const result = (await callable({ header, recipientIds, eventId })).data as any;

      return result;
    } catch (error) {
      console.error('Error:', error);

      return undefined;
    }
  }

  async sendPwaTestNotification() {
    try {
      const uri = this.getFunctionUri(`sendNotification`);

      const callable = httpsCallableFromURL(getFunctions(), uri);

      const result = (await callable()).data as any;

      return result;
    } catch (error) {
      console.error('Error:', error);

      return undefined;
    }
  }

  private async isAdminUser(userId: string) {
    const userItems = await this.firestore.collection('admin_debugging',
      (ref) => ref.where('userId', '==', userId))
      .get()
      .toPromise();

    if (userItems.empty) return false;
    return true;
  }

  private getFunctionUri(functionName: string) {
    const env = this.getEnviroment();

    // eslint-disable-next-line max-len
    return `https://us-central1-gametimehero-${env}.cloudfunctions.net/debugging-triggers-${functionName}`;
  }

  private getEnviroment(): string {
    return environment.envName!;
  }
}
