<h1 mat-dialog-title>Rate The Event</h1>

<mat-dialog-content *ngIf="participant$ | async as participant; else loadingTmpl">
  <div class="player-container">
    <img *ngIf="!imageError" [src]="participant.photoURL" (error)="imageError = true" />
    <h2 class="mat-headline-5">{{ participant.displayName }}</h2>
    <h3 class="mat-headline-5">Played {{ event.gameType }} with you {{ event.dateStart | date }}</h3>
  </div>

  <mat-divider></mat-divider>

  <div class="rating-container">
    <h2 class="mat-headline-5">What Went Great?</h2>
    <ng-container *ngIf="eventRatings as ratings">
      <mat-chip-listbox aria-label="Trait selection" multiple>
        <mat-chip-option *ngFor="let rating of ratings" [value]="rating.id"
          [selected]="ratingsSelection.isSelected(rating.id)"
          (click)="maxRatingsSelected && !ratingsSelection.isSelected(rating.id) ? undefined : ratingsSelection.toggle(rating.id)"
          [disabled]="maxRatingsSelected && !ratingsSelection.isSelected(rating.id)"
          [selectable]="!maxRatingsSelected">{{ rating.label }}</mat-chip-option>
      </mat-chip-listbox>
      <em>{{ratingsSelection.selected.length}} / 3</em>
    </ng-container>

    <button mat-stroked-button color="primary" class="submit-btn" [disabled]="ratingsSelection.selected.length > 3"
      (click)="ratePlayer(participant, ratingsSelection.selected, 'submit')">Submit</button>
    <button mat-button (click)="ratePlayer(participant, [], 'skip')">Skip</button>
    <button mat-button (click)="ratePlayer(participant, [], 'report')">Report</button>
  </div>
</mat-dialog-content>


<ng-template #loadingTmpl>
  <div class="fx-auto fx-fill fx-centered" *ngIf="!noParticipants; else noParticipantsTmpl">
    <mat-spinner diameter="36"></mat-spinner>
  </div>
</ng-template>

<ng-template #noParticipantsTmpl>
  <div class="fx-auto fx-centered">
    <p>No Participants</p>
  </div>
  <button mat-button mat-dialog-close>Close</button>
</ng-template>
