import { CommonModule } from '@angular/common';
import { Component, Inject, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { StorageService } from '@gth-legacy/services/storage.service';

export enum CloseMethod {
  SAVE,
  CANCEL,
  OTHER,
}

export interface DialogClosedProps {
  useSocialMedia: boolean;
  selectedImage: string | null;
  closeMethod: CloseMethod;
}

const KB_TO_MB = 10000000;
const MAX_FILE_SIZE_MB = 1;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * KB_TO_MB; // 1 MB

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  closeMethod = CloseMethod;
  title = 'Choose your avatar';

  imageNumbers = Array(24)
    .fill(0)
    .map((_, index) => index + 1);

  selectedAvatarIndex = -1;
  public loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { showSocialMediaButton: boolean; title: string },
    private dialogRef: MatDialogRef<AvatarComponent>,
    private storageService: StorageService,
    private snackbar: MatSnackBar,
  ) {
    if (this.data && this.data.title) {
      this.title = this.data.title;
    }
  }

  private getSelectedImage(imageURL?: string) {
    if (imageURL) return imageURL;
    else if (this.selectedAvatarIndex >= 0) {
      return `assets/avatars/avatar-${this.selectedAvatarIndex}.jpg`;
    }

    return null;
  }

  closeDialog(
    method: CloseMethod = CloseMethod.OTHER,
    useSocialMedia = false,
    uploadedImageURL?: string,
  ): DialogClosedProps {
    return {
      useSocialMedia,
      selectedImage: this.getSelectedImage(uploadedImageURL),
      closeMethod: method,
    };
  }

  public async onFileSelect(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const selectedFiles = inputElement.files;

    if (!selectedFiles || selectedFiles.length === 0) {
      this.snackbar.open('Select an image for upload.');
      return;
    }

    const file = selectedFiles[0];
    const fileSize = file.size / KB_TO_MB;
    if (fileSize > MAX_FILE_SIZE) {
      this.snackbar.open(`File must be smaller than ${MAX_FILE_SIZE_MB}MB.`);
      return;
    }

    try {
      this.loading = true;
      const filePath = `avatars/${Date.now()}_${file.name}`;
      const imageURL = await this.storageService.upload(filePath, file)
        .then(async (task) => await task.ref.getDownloadURL())
        .catch(() => {
          this.loading = false;
          this.snackbar.open(`Error uploading avatar.`);
          throw new Error(`Something went wrong uploading file`);
        });
      const closeResult = this.closeDialog(CloseMethod.SAVE, false, imageURL);
      this.dialogRef.close(closeResult);
    } catch (error: unknown) {
      this.snackbar.open(`Error uploading avatar.`);
      throw new Error((<Error>error).message);
    } finally {
      this.loading = false;
    }
  }
}
@NgModule({
  imports: [
    CommonModule, MatDialogModule, MatButtonModule,
    MatIconModule, MatToolbarModule, MatProgressSpinnerModule,
    MatSnackBarModule,
  ],
  exports: [AvatarComponent],
  declarations: [AvatarComponent],
  providers: [],
})
export class AvatarComponentModule { }
